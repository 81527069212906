import { Button, Container } from "src/components";
import { useStore } from "src/store";

export const TimePeriodBar = (): JSX.Element => {
  const [timePeriod, setTimePeriod] = useStore((state) => [state.timePeriod, state.setTimePeriod]);

  const handlTimePeriodChange = (e: React.MouseEvent<any>) => {
    const buttonClicked = e.currentTarget.name;
    setTimePeriod(buttonClicked);
  };

  return (
    <Container className="flex justify-between items-center">
      <h2 className="mb-2">Report Time Period</h2>
      <div className="flex items-center smMobile:flex-col gap-y-4">
        <Button
          name="7-days"
          variant={timePeriod === "7-days" ? "dark" : "light"}
          className="mx-2"
          onClick={handlTimePeriodChange}
        >
          Last 7 Days
        </Button>
        <Button
          name="4-weeks"
          variant={timePeriod === "4-weeks" ? "dark" : "light"}
          className="mx-2"
          onClick={handlTimePeriodChange}
        >
          Last 4 Weeks
        </Button>
        <Button
          name="6-months"
          variant={timePeriod === "6-months" ? "dark" : "light"}
          className="mx-2"
          onClick={handlTimePeriodChange}
        >
          Last 6 Months
        </Button>
      </div>
    </Container>
  );
};

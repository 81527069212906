import { createRef } from 'react';
import { AboutLatestEarnings, Box, Container } from 'src/components';
import { ItemType } from 'src/modules/Home/types';
import { useStore } from 'src/store';
import './LatestEarnings.scss';
import { EarnedItems } from './components';

export const LatestEarnings = (): JSX.Element => {
    const mainRef = createRef<HTMLDivElement>();
    const [reports, timePeriod] = useStore((state) => [state.reports, state.timePeriod]);

    const earnedItems = reports.itemReceived.filter(
        (item) => item.data.item?.type !== ItemType.Currency
    );

    return (
        <Container className="latest-earnings text-center md:text-left">
            <div className="relative" ref={mainRef}>
                <h2 className="text-center md:text-left mb-6">Latest Earnings</h2>
                <div className="latest-earnings_container gap-4">
                    <Box
                        className="flex flex-col items-center justify-center"
                        aboutcontent={<AboutLatestEarnings />}
                        modalContentStyle="laptop:max-h-[700px] mdLaptop:max-h-[420px] tablet:max-h-[650px] lgMobile:max-h-[600px] mobile:max-h-mobileModal smMobile:max-h-[620px] laptop:overflow-y-auto laptop:overflow-x-hidden"
                        rowref={mainRef}>
                        <img src={`${process.env.REACT_APP_PAGE_URL}icons/gear.png`} alt="" />
                        <h1>{earnedItems.length}</h1>
                        <h6 className="mb-3 text-center">
                            {`Gear Items collected for acing Word Games in the past ${timePeriod.replace(
                                '-',
                                ' '
                            )}`}
                        </h6>
                    </Box>
                    <div className="h-[320px] tablet:h-[350px] mdLaptop:h-[350px] laptop:h-[500px]">
                        <EarnedItems />
                    </div>
                </div>
                <div className="flex py-4">
                    <div className="bg-white w-[35px] h-[35px] rounded-[35px] shrink-0 mr-4 p-1 shadow-info">
                        <img
                            className="h-full w-full"
                            src={`${process.env.REACT_APP_PAGE_URL}icons/info.svg`}
                            alt=""
                        />
                    </div>
                    <p className="text-xs">
                        Parents/Educators, Kids learn because they are engaged, they are engaged
                        because of the rewards which are in this widget. So it will go a long way to
                        praise them on their collection of goodies
                    </p>
                </div>
            </div>
        </Container>
    );
};

/* 
    <Container className="total-earnings" aboutcontent={<AboutLatestEarnings />}>
    <h2 className="text-center md:text-left mb-6">latest earnings</h2>
    <div className="total-earnings_container gap-4">
      <EarnedSevenDays />
      <EarnedItems />
      <GearCount />
    </div>
    </Container>
*/
